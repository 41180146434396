<template>
  <el-container>
    <el-main>
          <h1>Login</h1>
      <el-form :model="userData" :rules="rulesUser" ref="userData" label-width="120px" class="demo-ruleForm"  :label-position="labelPosition">
        <el-row class="email">
          <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
            <el-form-item label="Email" prop="email" required>
              <el-input placeholder="Please input email" v-model="userData.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="password">
          <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8">
            <el-form-item label="Password" prop="password" required>
              <el-input placeholder="Please input password" v-model="userData.password" show-password minlength=8></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="login">
          <el-col :xs="12" :sm="6" :md="5" :lg="4" :xl="4">
            <el-form-item>
              <el-button type="primary" round v-on:click="login('userData')" >Login </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginComponent",
  data: function (){
    return {
      userData: {
        email: null,
        password: null
      },
      rulesUser: {
        email: [
          { required: true, message: 'Please input your email', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: 'Please input your password', trigger: 'blur' },
          { min: 8, message: 'Password must be min 8 characters', trigger: 'blur' }
        ]
      },
      labelPosition: 'top'
    }
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('http://localhost:8100/api/phone_verify', {
            email: this.userData.email,
            password: this.userData.password,
          })
              .then( (response) => {
                if(response.status === 200){
                  this.$router.push({ name: 'Register' })
                  this.successMessage('Phone was  verified', 'Your phone number was verified successfully')
                }
              })
              .catch( () => {
                this.errorMessage('Code is wrong', 'Verification Code is incorrect please try again')

              });
          //TODO send data to API internal  
          this.$notify.success({
            title: 'Success login ',
            message: 'Now you will be redirected to client portal'
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  }
}
</script>

<style scoped lang="css">

.email,.password,.login {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}



</style>