<template>
  <div class="login">
    <Login/>
  </div>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  name: "LoginUser",
  components: {
    Login
  }
}
</script>

<style scoped>

</style>